/* Barre de cookies */

.privacypolicies-com---nb-simple {
  width: 100% !important;
  max-width: 100% !important;
  background-color: rgba(#453b35,.90) !important;
  text-align: center !important;
  .cc-nb-title,
  .cc-nb-text{
    color: #FFFFFF !important;
    font-size: 16px !important;
  }
  .cc-nb-okagree {
    background-color:$brand-primary !important;
    border-radius: 0;
  }
  .cc-nb-reject {
    background-color:grey !important;
    border-radius: 0;
  }
  .cc-nb-changep {
    border-radius: 0;
  }
}
.privacypolicies-com---nb .cc-nb-main-container {
  padding: 1.5rem !important;
}
.privacypolicies-com---nb .cc-nb-text {
  line-height: 16px;
  font-size: 14px !important;
}