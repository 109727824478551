/* Formulaire édition du compte membre
---------------------------------------*/

.mod_personalData {
  form {
    margin-bottom:1rem;
  }
  .form-control {
    &.text {
      width: 30%;
    }
  }
}

/* Formulaire mot de passe perdu
---------------------------------------*/

.mod_lostPassword {
  form {
    margin-bottom: 1rem;
  }
  .form-control {
    &.text {
      width:30%;
    }
    &.captcha {
      width:30%;
    }
  }
}