.filtre-offres {
  background-color: $brand-primary;
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 3rem;
  .formbody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 575px) {
      flex-direction: column;
      gap: 1rem;
    }
    .widget {
      position: relative;
      width: 49%;
      @media (max-width: 575px) {
        width: 100%;
      }
      &:after{
        position: absolute;
        right: 1rem;
        top: 70%;
        transform: translateY(-55%);
        content:"\EA4D";
        font-family: kfont;
        font-size: 2rem;
        display: block;
        margin-left: -1rem;
        pointer-events: none;
      }
      select {
        width: 100%;
        height: 4.5rem;
        border: none;
        border-radius: .5rem;
        background-color: $text-lighter;
        padding-left: 1.5rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
}

.liste-offres {
  .wrap-item {
    position: relative;
    overflow: hidden;
    background-color: $background-alt;
    margin-bottom: 3rem;
    border-radius: 2rem;
    padding: 3rem;
    @media (max-width: 575px) {
      padding: 2rem;
    }
    .item {
      display: flex;
      align-items: center;
      gap: 4.8rem;
      .bloc-offres {
        .titre {
          h2 {
            font-size: 2.5rem;
            width: 85%;
            @media (max-width: 575px) {
              width: 80%;
              font-size: 2rem;
            }
          }
        }
        .image {
          figure {
            margin: 0;
            img {
              border-radius: 2rem;
            }
          }
        }
        .flex-offres {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1.6rem;
          flex-wrap: wrap;
          .poste, .departement {
            background-color: $brand-primary;
            padding: .5rem 1rem;
            border-radius: .3rem;
          }
        }
        .date {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: $text-color;
          color: white;
          height: 10rem;
          width: 10rem;
          border-bottom-left-radius: 2rem;
          @media (max-width: 764px) {
            height: 8rem;
            width: 8rem;
          }
          @media (max-width: 360px) {
            height: 6rem;
            width: 6rem;
          }
          .date-jour {
            font-size: 4rem;
            line-height: 1;
            @media (max-width: 764px) {
              font-size: 2.5rem;
            }
            @media (max-width: 360px) {
              font-size: 1.6rem;
            }
          }
          .date-mois {
            font-size: 2rem;
            line-height: 1;
            @media (max-width: 360px) {
              font-size: 1.4rem;
            }
          }
          .date-annee {
            font-size: 1.6rem;
            line-height: 1;
            @media (max-width: 360px) {
              font-size: 1.4rem;
            }
          }
        }
        .description {
          p {
            hyphens: auto;
          }
        }
      }
      .bloc-img {
        @media (max-width: 992px) {
          display: none;
        }
      }
    }
  }
}