.pace {
  margin: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: transparent !important;
  height: 3px !important;
  .pace-progress {
    background-color: rgba($brand-primary,100%) !important;
    max-width: 100% !important;
    transition: all 0.3s ease !important;
  }
}