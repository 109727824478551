.form-contact {
  .ce_form{
    color: $text-color;
    .widget-flex {
      display: flex;
      justify-content: space-between;
      @media (max-width: 575px) {
        flex-direction: column;
      }
    }
    .widget {
      display: flex;
      flex-direction: column;
      width: 48%;
      margin-bottom: 2rem;
      @media (max-width: 575px) {
        width: 100%;
      }
      input, select {
        width: 100%;
        height: 4.5rem;
        border-radius: .5rem;
        background-color: $text-lighter;
        border: solid 1px $grey;
        padding-left: 1.5rem;
      }
      textarea {
        width: 100%;
        border-radius: .5rem;
        background-color: $text-lighter;
        border: solid 1px $grey;
        padding-left: 1.5rem;
      }
      &.email{
        width: 100%;
      }
      &.widget-textarea {
        width: 100%;
      }
      &.widget-radio {
        width: 100%;
        .radio_container {
          width: unset;
          > label {
            margin-bottom: 1rem;
          }
        }
        .wrap-options {
          display: flex;
          align-items: center;
          gap: 2rem;
          span {
            display: flex;
            justify-content: left;
            align-items: center;
            gap: .5rem;
            input {
              width: 2rem;
              height: 2rem;
              min-width: 2rem;
              min-height: 2rem;
              cursor: pointer;
              &[type='radio'] {
                accent-color: $text-color;
              }
            }
          }
        }
      }
      &.widget-checkbox {
        width: 100%;
        .checkbox_container {
          width: unset;
          > label {
            margin-bottom: 1rem;
          }
          .wrap-options {
            span{
              display: flex;
              justify-content: left;
              align-items: center;
              gap: 2rem;
              input {
                min-width: 2rem;
                min-height: 2rem;
                max-width: 2rem;
                max-height: 2rem;
                border: none;
                cursor: pointer;
              }
              input[type=checkbox] {
                position: relative;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                background-color: $text-lighter;
                border: solid 1px $grey;
              }
              input[type="checkbox"]:checked {
                background: $text-color;
                &:after {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  color: $text-lighter;
                  content: "\EA61";
                  font-family: kfont;
                  font-size: 2.2rem;
                }
              }
            }
          }
        }
      }
      &.select {
        .wrap-data {
          position: relative;
          width: 100%;
          &:after{
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-51%);
            content:"\EA4D";
            font-family: kfont;
            font-size: 2rem;
            display: inline-flex;
            margin-left: -1rem;
            pointer-events: none;
          }
          select{
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
      }
      &.widget-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .wrap-data {
          width: 50%;
          @media (max-width: 575px) {
            width: 100%;
          }
          button {
            @include button;
            background-color: $brand-primary;
            width: 100%;
            &:hover {
              border: 2px $text-color solid;
            }
            &:after {
              content: "\EA62";
              font-family: kfont;
              font-size: 2rem;
            }
          }
        }
      }
    }
    .g-recaptcha {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
    }
    .error {
      color: red;
    }
  }
}
.carte {
  #map {
    border-radius: 1rem;
    @media (max-width: 575px) {
      height: 40rem !important;
    }
    a {
      margin: 0;
      &:after{
        display: none;
      }
    }
  }
}
