/* Mobile menu */
.mobile_menu {
  display: block;
  .bouton_menu_mobile {
    background-color:transparent;
    color: $text-color;
    display: block;
    z-index: 10;
    left: 3rem;
    position: absolute;
    top: 2.2rem;
    transition: top .3s;
    &.open {
      background: transparent;
    }
    .icon-bars {
      font-size: 2.5rem;
    }
  }
  .menu-side {
    background-color: $text-lighter;
    display: block;
    width: 50%;
    z-index: 9999999;
    height: 100%;
    left: -100%;
    position: fixed;
    top: 0;
    overflow: auto;
    @media (max-width: 750px) {
      width: 80%;
    }
    @media (max-width: 450px) {
      width: 100%;
    }
    .barre_menu_mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $text-lighter;
      height: 8rem;
      .bouton_menu_mobile {
        top: 2.2rem !important;
      }
    }
    .menu_mobile {
      margin-top: 5rem;
      ul {
        margin: 0;
        position: relative;
        padding: 0;
        & > li {
          background-color: #fff;
          margin: 0;
          list-style-type: none;
          &.nav-vert {
            a:hover {
              color: $brand-primary;
            }
            .level_2 {
              a:hover {
                color: $brand-primary !important;
              }
              .level_3 {
                a:hover {
                  color: $brand-primary !important;
                }
              }
            }
          }
          &.nav-bleu {
            a:hover {
              color: $brand-secondary;
            }
            .level_2 {
              a:hover {
                color: $brand-secondary !important;
              }
              .level_3 {
                a:hover {
                  color: $brand-secondary !important;
                }
              }
            }
          }
          & > a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $text-color;
            font-size: 1.6rem;
            padding: 1.8rem 3rem;
            width: 100%;
            font-family: "Satoshi Medium";
            border-bottom: 1px solid $text-color;
            transition: color .3s ease;
            &.submenu {
              &:after {
                content:"\EA4D";
                font-family: kfont;
                font-size: 2rem;
                display: inline-flex;
                margin-left: -1rem;
                transform: rotate(-0.25turn);
                transition: transform .3s;
              }
            }
          }
          ul {
            position: absolute;
            display: none;
            left: 100%;
            width: 100%;
            top: 0;
          }

        }

      }
      .level_1, .level_2, .level_3 {
        &.nav-vert {
          a:hover {
            color: $brand-primary;
          }
        }
        &.nav-bleu {
          a:hover {
            color: $brand-secondary;
          }
        }
      }
      .level_2 {
        //position: relative;
        //height: 100vh;
        //background-color: $text-lighter;
        li {
          a {
            &.submenu {
              &:after {
                content:"\EA4D";
                font-family: kfont;
                font-size: 2rem;
                display: inline-flex;
                margin-left: -1rem;
                transform: none;
                transition: transform .3s;
              }
            }
          }
        }
      }
      .level_3 {
        position: unset;
        display: none;
        li {
          a{
            padding-left: 6rem;
            &:not(.last){
              border: none !important;
            }
          }
        }
      }

      #menu_mobile_back {
        color: $text-color;
        font-size: 1.8rem;
        text-transform: none;
        justify-content: left;
        gap: 1rem;
        &:before {
          content:"\EA4D";
          font-family: kfont;
          font-size: 3rem;
          display: inline-flex;
          margin-left: -1rem;
          transform: rotate(-0.75turn);
          transition: transform .3s;
        }
        &:after {
          display: none;
        }
      }
    }
    .menu_bis_mobile {
      margin-top: 5rem;
      .menu-top {
        .level_1 {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            a {
              text-decoration: none;
              padding: 0 3rem;
            }
          }
        }
      }
    }
  }
}

.main-mobile {
  position: relative;
  z-index: 999;
  .level_2 {
    height: 100vh;
    background-color: $text-lighter;
  }
}

.top_mobile {
  a:hover {
    color: $brand-primary !important;
  }
}
