/* Galerie */

.galerie {

  &.mode_grille {

    &.w-100 .item { width:100% }
    &.w-50 .item { width:50% }
    &.w-33 .item { width:33% }
    &.w-25 .item { width:25% }
    &.w-20 .item { width:20% }
    &.w-16 .item { width:16% }
    &.w-14 .item { width:14% }
    &.w-12 .item { width:12% }
    &.w-11 .item { width:11% }
    &.w-10 .item { width:10% }
    &.w-9 .item { width:9% }
    &.w-8 .item { width:8% }

    &.w-50 .item {
      @media (max-width: 480px) { width:100%; }
    }

    &.w-33 .item {
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
    }

    &.w-25 .item {
      @media (max-width: 767px) { width:33%; }
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
    }

    &.w-20 .item {
      @media (max-width: 991px) { width:25%; }
      @media (max-width: 767px) { width:33%; }
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
    }

    &.w-16 .item {
      @media (max-width: 1199px) { width:20%; }
      @media (max-width: 991px) { width:25%; }
      @media (max-width: 767px) { width:33%; }
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
      @media (max-width: 1199px) { width:20%; }
    }

    &.w-14 .item {
      @media (max-width: 1300px) { width:16%; }
      @media (max-width: 1199px) { width:20%; }
      @media (max-width: 991px) { width:25%; }
      @media (max-width: 767px) { width:33%; }
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
      @media (max-width: 1199px) { width:20%; }
    }

    &.w-12 .item,
    &.w-11 .item,
    &.w-10 .item,
    &.w-9 .item,
    &.w-8 .item {
      @media (max-width: 1800px) { width:14%; }
      @media (max-width: 1300px) { width:16%; }
      @media (max-width: 1199px) { width:20%; }
      @media (max-width: 991px) { width:25%; }
      @media (max-width: 767px) { width:33%; }
      @media (max-width: 580px) { width:50%; }
      @media (max-width: 480px) { width:100%; }
      @media (max-width: 1199px) { width:20%; }
    }


    ul {

      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      align-items:top;
      justify-content: center;

      .item {

        text-align: center;
        padding:1.5rem;
        list-style-type: none;

      }

    }

  }

}