/* Formulaire */

.ce_form {
  .widget {
    /*display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom:1.5rem;
    @media (max-width: 767px) {
      display: block;
      label,
      .wrap-data {
        width: 100% !important;
        input,select,textarea {
          width: 100%;
        }
      }
      &.widget-submit .wrap-data {
        margin-left: 0 !important;
      }
    }*/
    &.w10 label { width:90%; }
    &.w10 .wrap-data { width:90%; }
    &.w20 label,&.w20 legend  { width:20%; }
    &.w20 .wrap-data { width:80%; }
    &.w30 label { width:30%; }
    &.w30 .wrap-data { width:70%; }
    &.w40 label { width:40%; }
    &.w40 .wrap-data { width:60%; }
    &.w50 label { width:50%; }
    &.w50 .wrap-data { width:50%; }
    &.w60 label { width:60%; }
    &.w60 .wrap-data { width:40%; }
    &.w70 label { width:70%; }
    &.w70 .wrap-data { width:30%; }
    &.w80 label { width:80%; }
    &.w80 .wrap-data { width:20%; }
    &.w90 label { width:90%; }
    &.w90 .wrap-data { width:10%; }
    &.w10.widget-submit .wrap-data { margin-left:10%; }
    &.w20.widget-submit .wrap-data { margin-left:20%; }
    &.w30.widget-submit .wrap-data { margin-left:30%; }
    &.w40.widget-submit .wrap-data { margin-left:40%; }
    &.w50.widget-submit .wrap-data { margin-left:50%; }
    &.w60.widget-submit .wrap-data { margin-left:60%; }
    &.w70.widget-submit .wrap-data { margin-left:70%; }
    &.w80.widget-submit .wrap-data { margin-left:80%; }
    &.w90.widget-submit .wrap-data { margin-left:90%; }
    //&.widget-radio
    //&.widget-checkbox {
    //  .radio_container,
    //  .checkbox_container {
    //    display: flex;
    //    flex-wrap: wrap;
    //    width: 100%;
    //    @media (max-width: 767px) {
    //      display: block;
    //    }
    //    .wrap-options {
    //      flex-grow: 1;
    //      span {
    //        display: block;
    //        input {
    //          display: inline-block;
    //        }
    //        label {
    //          display: contents;
    //        }
    //      }
    //    }
    //    &.inline {
    //      .wrap-options {
    //        span {
    //          margin-right:1.5rem;
    //          display: initial;
    //          @media (max-width: 767px) {
    //            display: block;
    //          }
    //        }
    //      }
    //    }
    //  }
    //}
    //&.widget-captcha {
    //  input {
    //    margin-right:1.5rem;
    //  }
    //}
  }
}