.popup-info {
  >div {
    padding: 1.5rem;
  }
  .button-close {
    padding: 0;
    background-color: #000000;
    color: #FFFFFF;
    white-space: nowrap;
    display: block;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 1rem;
  }
}