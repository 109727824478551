html {
  font-family: 'Outfit Regular', sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background-color: #FFFFFF;
  font-size: 1.6rem;
  overflow-x: hidden;
  font-family: 'Satoshi Regular', sans-serif;
}

.body-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.margin-body {
  margin-top: 10rem;
  @media (max-width: 991px) {
    margin-top: 5.5rem;
  }
}

.couleur-principal {
  color: $brand-primary;
}

.couleur-alternative {
  color: $brand-secondary;
}

.content-wrapper {
  padding:0;
  padding-bottom: 5.6rem;
  background-color: #FFFFFF;
  //@media (max-width: 767px) {
  //  padding:3rem;
  //}
}

body,
button,
fieldset,
form,
html,
textarea {

}

.overflow-hidden { overflow:hidden; }
.invisible { display:none !important; }
.inline-block { display:inline-block; }
.float_left { float:left; }
.float_right { float:right; }
.gauche { float:left; display:inline-block; vertical-align:top; }
.droite { float:right; }
.centre { text-align:center; }
.ce_image.centre figure, .ce_text.centre figure { margin:0 auto; }
.est-visible { opacity : 1 !important; }
.majuscule { text-transform: uppercase; }



/* Marges */

.marge-2x { padding:30px; }

.marge-bas { padding-bottom:10px; }
.marge-bas-2x { padding-bottom:30px; }

.marge-haut { padding-top:10px; }
.marge-haut-2x { padding-top:30px; }

.marge-gauche { padding-left:10px; }
.marge-gauche-2x { padding-left:30px; }

.marge-droite { padding-right:10px; }
.marge-droite-2x { padding-right:30px; }

.marges-auto { margin:0 auto; }


/* Bordures */

.bordure { border: 1px solid #DDDDDD; }
.bordure-2x { border: 2px solid #DDDDDD; }
.bordure-p { border: 1px dotted #DDDDDD; }
.bordure-t { border: 1px dashed #DDDDDD; }

.bordure-couleur { border: 1px solid $brand-primary; }
.bordure-couleur-2x { border: 2px solid $brand-primary; }
.bordure-couleur-p { border: 1px dotted $brand-primary; }
.bordure-couleur-t { border: 1px dashed $brand-primary; }

.bordure-bas { border-bottom: 1px solid #DDDDDD; }
.bordure-bas-2x { border-bottom: 2px solid #DDDDDD; }
.bordure-bas-p { border-bottom: 1px dotted #DDDDDD; }
.bordure-bas-t { border-bottom: 1px dashed #DDDDDD; }

.bordure-bas-couleur { border-bottom: 1px solid $brand-primary; }
.bordure-bas-couleur-2x { border-bottom: 2px solid $brand-primary; }
.bordure-bas-couleur-p { border-bottom: 1px dotted $brand-primary; }
.bordure-bas-couleur-t { border-bottom: 1px dashed $brand-primary; }

.bordure-haut { border-top: 1px solid #DDDDDD; }
.bordure-haut-2x { border-top: 2px solid #DDDDDD; }
.bordure-haut-p { border-top: 1px dotted #DDDDDD; }
.bordure-haut-t { border-top: 1px dashed #DDDDDD; }

.bordure-haut-couleur { border-top: 1px solid $brand-primary; }
.bordure-haut-couleur-2x { border-top: 2px solid $brand-primary; }
.bordure-haut-couleur-p { border-top: 1px dotted $brand-primary; }
.bordure-haut-couleur-t { border-top: 1px dashed $brand-primary; }

.bordure-droite { border-right: 1px solid #DDDDDD; }
.bordure-droite-2x { border-right: 2px solid #DDDDDD; }

.bordure-droite-couleur { border-right: 1px solid $brand-primary; }
.bordure-droite-couleur-2x { border-right: 2px solid $brand-primary; }

.bordure-gauche { border-left: 1px solid #DDDDDD; }
.bordure-gauche-2x { border-left: 2px solid #DDDDDD; }

.bordure-gauche-couleur { border-left: 1px solid $brand-primary; }
.bordure-gauche-couleur-2x { border-left: 2px solid $brand-primary; }


/* Couleurs de fond */

.fond { background-color: #F1F1F1; }
.fond-couleur { background-color: $brand-primary; color:#FFFFFF; }
.fond-blanc { background-color: #FFFFFF; }
.fond-noir { background-color: #413E41; color:#FFFFFF; }

.fond-couleur a,
.fond-couleur a:hover,
.fond-couleur p,
.fond-couleur li,
.fond-couleur .caption {
  color:#FFFFFF;
}

/* Couleurs */

.couleur { color: $brand-primary; }

.gris { color: #e4e2df; }
.noir { color: #372c26; }
.blanc { color: #FFFFFF; }
.color { color: $brand-primary; }

p:empty {
  display: none;
}