/* Retour haut de page */
#topcontrol {
  display: none;
  .icon {
    display: block;
    background-color: #727272;
    color: #FFFFFF;
    font-size: 1.5rem;
    height: 4rem;
    padding-top: 1rem;
    text-align: center;
    width: 4.5rem;
  }
}