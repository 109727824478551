/*************/
/* Home page */
/*************/

.page-accueil {
  section:not(.galerie-partenaires) {
    margin-bottom: 5rem;
  }
  section {
    padding:5rem;
    @media (max-width: 767px) {
      padding: 3rem;
      >.container {
        padding-left:0;
        padding-right:0;
      }
    }
  }
  .content-wrapper {
    padding-top:0;
    padding-bottom:0;
    @media (max-width: 767px) {
      padding:0;
    }
  }

  .zone-presentation{
    background-color: $background-alt;
    .container {
      max-width: 150rem;
      .mod_article .first {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5.7rem;
        @media (max-width: 1550px) {
          gap: 3rem;
        }
        @media (max-width: 1100px) {
          flex-direction: column;
          align-items: baseline;
          gap: 3rem 0;
        }
        .bloc-gauche {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: baseline;
          gap: 1.6rem;
          width: 50%;
          @media (max-width: 1550px) {
            width: 60%;
          }
          @media (max-width: 1200px) {
            width: 75%;
          }
          @media (max-width: 1100px) {
            width: 100%;
          }
          .bloc-surtitres {
            display: flex;
            align-items: center;
            gap: 4rem;
            @media (max-width: 575px) {
              flex-direction: column;
              align-items: baseline;
              gap: 2rem;
            }
            .bloc-surtitre {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1.2rem;
              .image img {
                aspect-ratio: 50/40;
                object-fit: none;
                border-radius: 0;
                max-width: none;
              }
              .surtitre {
                font-family: "Satoshi Medium";
                font-size: 1.8rem;
                color: $grey;
                @media (max-width: 1550px) {
                  font-size: 1.6rem;
                }
              }
            }
          }
          .h1-home {
            font-family: "Satoshi Black";
            font-size: 4.8rem;
            line-height: 1.2;
            margin: 0;
            color: $text-color;
            @media (max-width: 1550px) {
              font-size: 4rem;
            }
            @media (max-width: 1200px) {
              font-size: 3rem;
            }
          }
        }
        .img-presentation img {
          aspect-ratio: 711/460;
          max-width: 71.1rem;
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 8rem;
          @media (max-width: 1200px) {
            border-radius: 7rem;
          }
          @media (max-width: 575px) {
            border-radius: 3rem;
          }
        }
      }
    }
  }


  /* Actualités */
  .zone-actualites {
    .mod_newslist {
      .swiper-container{
        position: unset;
        .swiper-slide {
          height: unset;
          min-height: 100%;
        }
        .swiper-pagination {
          z-index: 30;
          bottom: -3.5rem;

          .swiper-pagination-bullet {
            width: 1.6rem;
            height: 1.6rem;
            background-color: $background-alt;
            opacity: 1;
            transition: all .3s ease;
          }
          .swiper-pagination-bullet-active {
            width: 7.9rem;
            border-radius: 10rem;
            background-color: $brand-primary;
          }
        }
      }
      .swiper-buttons {
        display: none;
      }
    }
  }

  /* Evenèments */
  .zone-ecoles {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        gap: 2.4rem;
      }
      >.block {
        width: 100%;
      }
      .ecole-gauche, .ecole-droit {
        border: 1px #e1e1e1 solid;
        border-radius: .8rem;
        padding: 1.6rem;
        width: 49%;
        @media (max-width: 992px) {
          width: 100%;
        }
        .logo-titre {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1.6rem;
          margin-bottom: 2.4rem;
          .text {
            font-family: "Satoshi Bold";
            font-size: 2.4rem;
            @media (max-width: 1200px) {
              font-size: 1.8rem;
            }
          }
        }
        a{
          @include button;
          margin-bottom: 1.2rem;
          padding: .8rem;
          max-width: 100%;
          width: 100%;
          gap: 0;
          &:hover {
            border: 2px $text-color solid;
          }
          &:after {
            content: "\EA4D";
            font-family: kfont;
            font-size: 2.5rem;
            transform: rotate(-90deg);
          }
        }
      }
      .ecole-gauche {
        a{
          background-color: $brand-primary;
        }
      }

      .ecole-droit {
        a{
          background-color: $brand-secondary;
        }
      }
    }
  }
}