

@mixin button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7.8rem;
  padding: 1rem 1.6rem;
  border-radius: .8rem;
  font-size: 1.8rem;
  font-family: "Satoshi Medium";
  line-height: normal;
  border: 2px transparent solid;
  transition: all .3s ease;
  text-decoration: none !important;
  color: $text-color;
  @media (max-width: 1200px) {
    font-size: 1.6rem;
    padding: 1rem 1.4rem;
  }
  @media (max-width: 480px) {
    width: 100% !important;
  }
}

@mixin button-vert {
  background-color: $brand-primary;
}
@mixin button-bleu {
  background-color: $brand-secondary;
}

.button {
  @include button;
  &:hover {
    border: 2px $text-color solid;
  }
  &.button-vert{
    background-color: $brand-primary;
  }
  &.button-fleche-dh {
    &:after {
      content: "\EA54";
      font-family: kfont;
      font-size: 2.2rem;
      transform: rotate(-135deg);
    }
  }
  &.button-fleche {
    &:after {
      content: "\EA54";
      font-family: kfont;
      font-size: 2rem;
    }
  }
  &.button-download {
    &:after {
      content: "\EA5D";
      font-family: kfont;
      font-size: 2rem;
    }
  }

  &.button-chevron {
    &:after {
      content: "\EA4D";
      font-family: kfont;
      font-size: 2rem;
    }
  }
}

.btn-contact {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 70rem;
    right: 1rem;
    width: 48px;
    height: 48px;
    padding: 3px;
    background-color: $brand-primary;
    font-size: 0;
    border-radius: 1rem;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease;
    @media (max-width: 1200px) {
      top: 56rem;
    }
    &:after {
      content: "\EA51";
      font-family: kfont;
      font-size: 2.5rem;
      color: $text-lighter;
    }
  }
  &.active {
    a{
      opacity: 1;
      pointer-events: all;
    }
  }
}
/* Boutons */

//vert
.body-wrapper:not(.page-ess) {
  .button {
    @include button-vert;
  }
  .btn-contact {
    a {
      background: $brand-primary;
    }
  }
  .btn-u {
    color: $brand-primary;
  }
}


//bleu
.page-ess {
  .button {
    @include button-bleu;
  }
  .btn-contact {
    a {
      background: $brand-secondary;
    }
  }
  .btn-u {
    color: $brand-secondary;
  }
}