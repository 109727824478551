/**********/
/* News   */
/**********/

/* Bloc news */
.bloc-news {
  display: flex;
  align-items: center;
  gap: 4.8rem;
  background-color: $background-alt;
  border-radius: 8.5rem;
  padding: 2.4rem;
  height: 100%;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: initial;
    gap: 3rem;
    border-radius: 5.5rem;
  }
  @media (max-width: 767px) {
    border-radius: 3rem;
  }
  @media (max-width: 575px) {
    padding: 2rem;
  }
  .date {
    display: none;
  }
  .image {
    .img-fluid{
      aspect-ratio: 445/296;
      object-fit: cover;
      max-width: 44.5rem;
      max-height: 29.6rem;
      border-radius: 7.2rem;
      @media (max-width: 992px) {
        border-radius: 5.5rem;
        max-width: inherit;
        max-height: inherit;
      }
      @media (max-width: 767px) {
        border-radius: 3rem;
      }
    }
    .image_container, .img-fluid {
      width: max-content !important;
      margin: 0;
      @media (max-width: 992px) {
        width: 100% !important;
      }
    }
    .wrap-img {
      border-radius: 7.2rem;
      @media (max-width: 992px) {
        border-radius: 5.5rem;
        max-width: inherit;
        max-height: inherit;
      }
      @media (max-width: 767px) {
        border-radius: 3rem;
      }
      img {
        aspect-ratio: 445/296;
        object-fit: cover;
        width: 44.5rem;
        height: 29.6rem;
        border-radius: 7.2rem;
        @media (max-width: 992px) {
          border-radius: 5.5rem;
          width: 100%;
          height: 100%;
          max-width: inherit;
          max-height: inherit;
        }
        @media (max-width: 767px) {
          border-radius: 3rem;
        }
      }
    }
  }
  .bloc-desc {
    .titre-actu {
      font-size: 2.4rem;
      font-family: "Satoshi Bold";
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .teaser {
      text-align: left;
      font-size: 1.6rem;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .bt-detail {
      width: fit-content;
    }
  }
  &.clickable {
    cursor: pointer;
    &:hover {
      .image {
      }
    }
  }
}

.mod_newsreader {
  h2 {
    font-size: 4rem !important;
    margin-bottom: 4rem;
    @media (max-width: 1200px) {
      font-size: 3rem !important;
    }
  }
  h3 {
    font-size: 2rem !important;
  }

  img {
    border-radius: 5rem;
  }
}