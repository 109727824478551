/* Fil d'arianne */

//vert
.body-wrapper:not(.page-ess) {
  .breadcrumbs {
    li {
      a:hover {
        color: $brand-primary;
      }
      &.active {
        color: $brand-primary !important;
      }
    }
  }
}


//bleu
.page-ess {
  .breadcrumbs {
    li {
      a:hover {
        color: $brand-secondary;
      }
      &.active {
        color: $brand-secondary !important;
      }
    }
  }
}


.breadcrumbs {
  padding-top: 3rem;
  .container {
    @media (max-width: 575px) {
      padding: 0 2rem;
    }
    .breadcrumb {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin:0;
      background: none;
      li {
        color: $grey;
        line-height: .8;
        &.first{
          text-decoration: underline $grey;
        }
        a:hover {
          text-decoration: none;
        }
      }
      a {
        color: $grey;
        transition: color .3s ease;
      }
      >li + li::before {
        color: $grey;
        padding-right: 1rem;
        font-size: 2rem;
        content: ">";
      }
    }
  }
}