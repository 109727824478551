.zone-principale, .zone-gauche, .zone-droite, .zone-bas {
  .objectif {
    h2 {
      &:before {
        content: "\EA5A";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .liste {
    h2 {
      &:before {
        content: "\EA57";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .formation {
    h2 {
      &:before {
        content: "\EA55";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .livre {
    h2 {
      &:before {
        content: "\EA60";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .billet {
    h2 {
      &:before {
        content: "\EA4E";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .cartable {
    h2 {
      &:before {
        content: "\EA50";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .stylo {
    h2 {
      &:before {
        content: "\EA5C";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .brochure {
    h2 {
      &:before {
        content: "\EA4F";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .inscription {
    h2 {
      &:before {
        content: "\EA56";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .utilisateurs {
    h2 {
      &:before {
        content: "\EA74";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .trophee {
    h2 {
      &:before {
        content: "\EA73";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .signalisation {
    h2 {
      &:before {
        content: "\EA72";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .accessibilite {
    h2 {
      &:before {
        content: "\EA63";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .arobase {
    h2 {
      &:before {
        content: "\EA64";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .recompense {
    h2 {
      &:before {
        content: "\EA65";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .alerte {
    h2 {
      &:before {
        content: "\EA66";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .badge {
    h2 {
      &:before {
        content: "\EA67";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .bus {
    h2 {
      &:before {
        content: "\EA68";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .calendrier {
    h2 {
      &:before {
        content: "\EA69";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .personne {
    h2 {
      &:before {
        content: "\EA6A";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .groupe {
    h2 {
      &:before {
        content: "\EA6B";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .telechargement {
    h2 {
      &:before {
        content: "\EA6C";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .hashtag {
    h2 {
      &:before {
        content: "\EA6D";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .infini {
    h2 {
      &:before {
        content: "\EA6E";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .info {
    h2 {
      &:before {
        content: "\EA6F";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .medaille {
    h2 {
      &:before {
        content: "\EA70";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }

  .panneau {
    h2 {
      &:before {
        content: "\EA71";
        font-family: kfont;
        font-size: 2.2rem;
      }
    }
  }
}