/* Pagination */
.pagination {
  margin-top:1rem;
  margin-bottom:1rem;
  justify-content: center;
  li {
    a {
      padding: 0.5rem 1.5rem;
      color: #777;
      &:hover {
        background: $brand-primary;
        border-color: #DDDDDD;
        color: #fff;
        text-decoration: none;
      }
    }
    &.page-item:first-child .page-link {
      border-top-left-radius: .4rem;
      border-bottom-left-radius: .4rem;
    }
    &.page-item:last-child .page-link {
      border-top-right-radius: .4rem;
      border-bottom-right-radius: .4rem;
    }
  }
  & > .active > a,
  & > .active > span,
  & > .active > a:hover,
  & > .active > span:hover,
  & > .active > a:focus,
  & > .active > span:focus {
    background-color: $brand-primary !important;
    border-color: #DDDDDD !important;
  }
  > li {
    > span {
      padding: 0.5rem 1.5rem !important;
      &.current {
        background-color: $brand-primary;
        color: #FFFFFF;
      }
    }
    > a {
      padding: 0.5rem 1.5rem !important;
    }
  }
  &.block {
    width:100%;
    p {
      margin-bottom:0;
    }
  }
}