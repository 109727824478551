@mixin title-global {
  word-wrap: normal;
  hyphens: none;
  line-height: 1;
}

@mixin h1 {
  font-family: "Satoshi Black";
  font-size: 4.8rem;
  line-height: 1.2;
  margin: 0;
  color: $text-color;
  @media (max-width: 1550px) {
    font-size: 4rem;
  }
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
}

@mixin h2 {
  font-size: 3.2rem;
  font-family: "Satoshi Black";
  color: $text-color;
  margin-bottom: 1.6rem;
}

@mixin h3 {
  font-size: 2.4rem;
  margin-top:0;
  margin-bottom: 2rem;
}

@mixin h4 {
  font-size: 1.8rem;
  margin-top:0;
  margin-bottom: 2rem;
}

@mixin h5 {
  font-size: 1.4rem;
  margin-top:0;
  margin-bottom: 1.5rem;
}

@mixin h6 {
  font-size: 1.2rem;
  margin-top:0;
  margin-bottom: 1rem;
}