/**********/
/* Header */
/**********/

.body-wrapper:not(.page-ess) {
  .header {
    .header_top {
      background: $brand-primary;
    }
  }
}


//bleu
.page-ess {
  .header {
    .header_top {
      background: $brand-secondary;
    }
  }
}

.header {
  background-color: #FFFFFF;
  position: relative;
  &.isStuck {
    width: 100%;
    left: 0;
    z-index: 9999;
    box-shadow: 0px 10px 5px 0px rgba(#656565, 0.3);
    .header_menu .container .flex-nav .logo img {
      width: 5rem;
    }
    .bouton_menu_mobile {
      top: 1.4rem;
    }
  }
  .container {
    max-width: 1600px;
  }
  /* Header top */
  .header_top {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right:0;
    top:0;
    padding: 0 4rem;
    width: 100%;
    @media (max-width: 991px) {
      display: none;
    }
    .container {
      display: flex;
      justify-content: right;
      .menu-top {
        ul {
          display: flex;
          align-items: center;
          gap: 4rem;
          margin-bottom: 0;
          li {
            list-style: none;
            margin: 1rem 0;
            a {
              display: flex;
              align-items: center;
              gap: .5rem;
              color: $text-color;
              font-size: 1.4rem;
              text-decoration: none;
              transition: color .3s;
              &:hover {
                color: $text-lighter;
              }
              &.facebook::before {
                content: "\EA52";
                font-family: kfont;
                font-size: 2rem;
              }
              &.offres::before {
                content: "\EA5F";
                font-family: kfont;
                font-size: 2rem;
              }
            }
          }
        }
      }
    }
  }

  /* Header menu */
  .header_menu {
    position:relative;
    padding: 0 4rem;
   .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      .flex-nav {
        display: flex;
        align-items: center;
        gap: 6.4rem;
        @media (max-width: 500px) {
          gap: 2rem;
        }
        .main_menu {
          @media (max-width: 991px) {
            display: none;
          }
        }
        .logo {
          display: flex;
          justify-content: center;
          width: 10rem;
          @media (max-width: 991px) {
            padding: 1rem 0;
          }
          img {
            width: 8rem;
            transition: width .3s;
            @media (max-width: 991px) {
              width: 7rem;
            }
          }
        }
      }
      .contact-button {
        ul {
          margin-bottom: 0;
          li {
            list-style: none;
            a {
              font-family: "Satoshi Medium";
              display: flex;
              align-items: center;
              gap: .5rem;
              text-decoration: none;
              color: $text-color;

              &:before {
                content: "\EA51";
                font-size: 2rem;
                font-family: kfont;
              }
            }
          }
        }
      }
    }
  }
}
