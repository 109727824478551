/* Sticky right */
.pin-wrapper {
  position:absolute;
  right:0;
  z-index:9;
  opacity:0.8;
  transition:opacity 0.3s ease;
  &:hover {
    opacity:1;
  }
  .reseaux-sociaux {
    width:5rem;
    text-align: center;
    padding:1rem;
    background-color: #FFFFFF;
    a {
      display: block;
      .icon {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }
}