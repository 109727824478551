.galerie-partenaires {
  background-color: $background-alt;
  .galerie {
    width: 100% !important;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      .image_container {
        img {
          @media (max-width: 575px) {
            width: 70%;
          }
        }
      }
    }
  }
}