//@font-face {
//  font-family: 'Poppins';
//  src: local('Poppins Light'), local('Poppins-Light'),
//  url('../fonts/subset-Poppins-Light.woff2') format('woff2'),
//  url('../fonts/subset-Poppins-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
/* desactive pour le backo */
/*
html body:not([class*="breakpoint"]) {

  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .pressstyle {
    font-family: 'Press Style', fantasy, sans-serif;
  }

}
*/

@font-face {
  font-family: 'Outfit Medium';
  src: local('Outfit Medium'), local('Outfit-Medium'),
  url('../fonts/subset-Outfit-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit Light';
  src: local('Outfit Light'), local('Outfit-Light'),
  url('../fonts/subset-Outfit-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit Regular';
  src: local('Outfit Regular'), local('Outfit-Regular'),
  url('../fonts/subset-Outfit-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit Bold';
  src: local('Outfit Bold'), local('Outfit-Bold'),
  url('../fonts/subset-Outfit-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit Extrabold';
  src: local('Outfit ExtraBold'), local('Outfit-ExtraBold'),
  url('../fonts/subset-Outfit-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit Semibold';
  src: local('Outfit SemiBold'), local('Outfit-SemiBold'),
  url('../fonts/subset-Outfit-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Light';
  src: url('../fonts/Satoshi-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Regular';
  src: url('../fonts/Satoshi-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Medium';
  src: url('../fonts/Satoshi-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Bold';
  src: url('../fonts/Satoshi-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi Black';
  src: url('../fonts/Satoshi-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}