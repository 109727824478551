//vert
.body-wrapper:not(.page-ess) {
  .zone-bandeau a {
    background-color: $brand-primary;
  }
  .zone-principale, .zone-gauche, .zone-droite, .zone-bas {
    .ce_text a {
      color: $brand-primary;
      text-decoration: underline $brand-primary;
    }
    .bloc-complet a{
      background-color: $brand-primary;
    }
    .ce_download {
      a {
        color: $brand-primary;
      }
    }
  }
}


//bleu
.page-ess {
  .zone-bandeau a {
    background-color: $brand-secondary;
  }
  .zone-principale, .zone-gauche, .zone-droite, .zone-bas {
    .ce_text a {
      color: $brand-secondary;
      text-decoration: underline $brand-secondary;
    }
    .bloc-complet a{
      background-color: $brand-secondary;
    }
    .ce_download {
      a {
        color: $brand-secondary;
      }
    }
  }
}

.intro-page {
  background-color: $background-alt;
  padding: 5.6rem 0;
  .nom-page {
    display: flex;
    justify-content: center;
    .ce_image {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 1.2rem;
      margin-bottom: 2rem;
      h3 {
        font-family: "Satoshi Bold";
        font-size: 2.4rem;
        color: $grey;
        margin: 0;
      }
    }
  }
  h1 {
    text-align: center;
    margin: 0;
  }
  .zone-bandeau {
    margin-top: 2rem;
    .mod_article .container {
      display: flex;
      justify-content: center;
      gap: 2.4rem;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 1rem;
      }
      .bouton-bandeau-couleur {
        a{
          @include button;
          width: 31rem;
          @media (max-width: 575px) {
            width: 29rem !important;
          }
          &:hover {
            border: 2px $text-color solid;
          }
          &:after {
            content: "\EA54";
            font-family: kfont;
            font-size: 2.2rem;
            transform: rotate(-135deg);
          }
        }
      }
      .bouton-bandeau-image {
        @include button;
        border: 2px #000091 solid;
        width: 31rem;
        background-color: $text-lighter;
        @media (max-width: 575px) {
          width: 29rem !important;
        }
        &:after {
          content: "\EA54";
          font-family: kfont;
          font-size: 2.2rem;
          transform: rotate(-135deg);
          color: #000091;
        }
        .image {
          img {
            max-height: 24px;
            width: auto;
          }
        }
      }
    }
  }
}

.zone-principale, .zone-bas {

  .container {
    @media (max-width: 575px) {
      padding: 0 2rem;
    }
  }
}

.zone-gauche {
  .container {
    padding-left: 0;
    @media (max-width: 767px) {
      padding: 0;
    }
    @media (max-width: 575px) {
      padding: 1.5rem;
    }
  }
}

.zone-droite {
  .container {
    padding-right: 0;
    @media (max-width: 767px) {
      padding: 0;
    }
    @media (max-width: 575px) {
      padding: 1.5rem;
    }
  }
}

.zone-principale, .zone-gauche, .zone-droite, .zone-bas {
  .mod_article {
    margin-top: 4rem;

    p {
      text-wrap: balance;
    }
  }

  .background-vert {
    background-color: $brand-primary;
    padding: 3rem 0;
  }

  .background-bleu {
    background-color: $brand-secondary;
    padding: 3rem 0;
  }

  .background-gris {
    background-color: $background-alt;
    padding: 3rem 0;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: .8rem;
    font-family: "Satoshi Bold";
    font-size: 2rem;
  }

  h3 {
    font-family: "Satoshi Bold";
    font-size: 1.6rem;
  }

  p {
    strong {
      font-family: "Satoshi Bold";
      font-size: 1.6rem;
    }
  }

  a {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    margin-right: 5rem;

    &:after {
      position: absolute;
      right: -2.5rem;
      content: "\EA54";
      font-family: kfont;
      font-size: 2.2rem;
      transform: rotate(-135deg);
      text-decoration: none;
    }
  }

  .table-overflow {
    overflow-x: auto;
    padding-bottom: 1rem;
    margin: 1.5rem 0 .5rem 0;
    table {
      min-width: 70rem;
      border: 1px solid $text-color;
      border-radius: 4rem;

      tr {
        td {
          border: 1px solid $text-color;
          padding: 2rem;
        }
      }

      thead {
        tr {
          td {
            font-family: "Satoshi Bold";
          }
        }
      }
    }
  }

  .bloc-complet {
    display: flex;
    align-items: center;
    gap: 4.8rem;
    background-color: $background-alt;
    border-radius: 8.5rem;
    padding: 2.4rem;
    @media (max-width: 992px) {
      align-items: initial;
      flex-direction: column;
      border-radius: 5.5rem;
      gap: 3rem;
    }
    @media (max-width: 767px) {
      border-radius: 3rem;
    }
    @media (max-width: 575px) {
      padding: 2rem;
    }

    .image {
      img {
        aspect-ratio: 445/296;
        object-fit: cover;
        max-width: 44.5rem;
        max-height: 29.6rem;
        border-radius: 7.2rem;
        @media (max-width: 992px) {
          border-radius: 5.5rem;
          max-width: inherit;
          max-height: inherit;
        }
        @media (max-width: 767px) {
          border-radius: 3rem;
        }
      }
    }

    .bloc-texte {
      h2 {
        font-size: 2.4rem;
      }

      a {
        @include button;
        width: fit-content;
        margin-top: 2rem;

        &:hover {
          border: 2px $text-color solid;
        }

        &:after {
          position: unset;
          content: "\EA54";
          font-family: kfont;
          font-size: 2.2rem;
          transform: rotate(-135deg);
        }
      }
    }
  }

  .mode-grille {
    width: 100% !important;
  }

  .bloc-brochure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4.8rem;
    @media (max-width: 992px) {
      align-items: initial;
      flex-direction: column;
      gap: 3rem;
    }

    .bloc-texte {
      h2 {
        &:before {
          content: "\EA4F";
          font-family: kfont;
          font-size: 2.2rem;
        }
      }

      a {
        @include button;
        background-color: $text-lighter;
        width: fit-content;

        &:hover {
          border: 2px $text-color solid;
        }

        &:after {
          position: unset;
          content: "\EA5D";
          font-family: kfont;
          font-size: 2.2rem;
          transform: rotate(0);
        }
      }
    }

    .image {
      img {
        object-fit: cover;
        max-width: 60rem;
        max-height: 13.5rem;
        border-radius: 4rem;
        @media (max-width: 1200px) {
          max-width: 100%;
        }
        @media (max-width: 767px) {
          border-radius: 3rem;
        }
      }
    }
  }

  .slider-pages {
    background-color: $background-alt;
    padding: 1.6rem 6rem;
    border-radius: 7.2rem;
    @media (max-width: 992px) {
      border-radius: 5.5rem;
      padding: 1.6rem 4rem;
    }
    @media (max-width: 767px) {
      border-radius: 3rem;
    }

    .swiper.mode-carousel .swiper-buttons {
      width: 100%;
      height: 100%;
      top: 0;

      .swiper-button-prev {
        left: -4.3rem !important;
        top: 50% !important;
        transform: translateY(-50%);
        @media (max-width: 767px) {
          left: -3.5rem !important;
        }
      }

      .swiper-button-next {
        right: -4rem !important;
        top: 50% !important;
        transform: translateY(-50%);
        @media (max-width: 767px) {
          border-radius: 3rem;
          right: -3rem !important;
        }
      }
    }

    .swiper-pagination {
      display: none;
    }

    .image_container {
      img {
        aspect-ratio: 345/225;
        width: 100%;
        height: 100%;
        border-radius: 4.8rem;
        @media (max-width: 992px) {
          border-radius: 5.5rem;
        }
        @media (max-width: 767px) {
          border-radius: 3rem;
        }
      }
    }
  }

  .ce_download {
    .icon {
      display: none;
    }
    a {
      &:after {
        content: "\EA5D";
        font-family: kfont;
        font-size: 2rem;
        transform: rotate(0);
      }
    }
  }

  .frise {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
    @media (max-width: 992px) {
      flex-direction: row-reverse;
      align-items: start;
      gap: 5rem;
    }
    @media (max-width: 500px) {
      gap: 1rem;
    }

    .frise-ligne {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: .5rem;
      @media (max-width: 992px) {
        justify-content: left;
        margin-top: 7rem;
      }

      .bille-frise {
        position: relative;
        width: 1.6rem;
        height: 1.6rem;
        background-color: $brand-primary;
        border-radius: 99rem;
      }

      .barre-frise {
        width: .1rem;
        height: 16rem;
        background-color: $brand-primary;
        &.barre-1 {
          @media (max-width: 992px) {
            height: 19rem;
          }
          @media (max-width: 767px) {
            height: 19rem;
          }
          @media (max-width: 500px) {
            height: 22rem;
          }
          @media (max-width: 350px) {
            height: 28rem;
          }

        }
        &.barre-2 {
          @media (max-width: 992px) {
            height: 23rem;
          }
          @media (max-width: 767px) {
            height: 26rem;
          }
          @media (max-width: 500px) {
            height: 29rem;
          }
          @media (max-width: 390px) {
            height: 33rem;
          }
          @media (max-width: 320px) {
            height: 45rem;
          }
        }
      }
    }

    .frise-first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 10rem;
      @media (max-width: 992px) {
        gap: 4rem;
      }
      .bloc-2 {
        display: none;
        @media (max-width: 992px) {
          display: block;
        }
      }
      .frise-vide {
        height: 4.5rem;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    .frise-second {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  .bloc-frise {
    border-radius: .5rem;
    padding: 2rem;
    background-color: $background-alt;
    width: 53.6rem;
    @media (max-width: 1399px) {
      width: 45rem;
    }
    @media (max-width: 1200px) {
      width: 40rem;
    }
    @media (max-width: 992px) {
      width: 100%;
    }

    &.bloc-1 {
      transform: translateY(40%);
      @media (max-width: 992px) {
        transform: none;
      }
    }
    .titre {
      font-family: "Satoshi Bold";
      font-size: 1.8rem;
      margin-bottom: .5rem;
    }
  }
}

.radius-standart {
  img {
    border-radius: 6rem;
    @media (max-width: 1200px) {
      border-radius: 3rem;
    }
    @media (max-width: 767px) {
      margin-bottom: 4rem;
    }
  }
}
