/* Menu de langue */
.menu-langues {
  margin-left:1rem;
}

.mod_changelanguage {
  ul {
    margin: 0;
    padding:0;
    display: flex;
    align-items: center;
    li {
      margin-left:0.5rem;
      list-style-type: none;
      a {
        margin-left:0 !important;
      }
      &.active {
        a {
          font-weight: bold;
        }
      }
    }
  }
}