/* Vidéo Youtube */

.ce_contenu_video_youtube {
  .video {
    float: left;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    position: relative;
    width: 33%;
    &.centre {
      .video {
        float: none;
        margin: 0 auto;
      }
    }
  }
  .conteneur_video {
    height: 0;
    padding-bottom: 75%;
    padding-top: 2.5rem;
    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.container-videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .video-youtube {
    width: 50%;
    padding:1.5rem;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}