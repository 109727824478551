/* Accordéons */
.ce_accordion {
  margin-bottom:1rem;
  .toggler {
    padding:1rem;
    border:1px solid #DDDDDD;
    cursor:pointer;
    font-family: Outfit medium;
    font-size: 1.8rem;
    &:hover {
      text-decoration:underline;
      color:$brand-primary;
    }
  }
  .toggler.active {
    border-bottom:0;
  }
  .accordion {
    padding:1rem;
    border:1px solid #DDDDDD;
    &[aria-hidden=false]{
      border-bottom:1px solid #DDDDDD !important;
    }
  }
}