/**********/
/* Footer */
/**********/


//vert
.body-wrapper:not(.page-ess) {
  .footer {
    .footer_main {
      background: $brand-primary;
    }
  }
}


//bleu
.page-ess {
  .footer {
    .footer_main {
      background: $brand-secondary;
    }
  }
}

.footer {
  /* Main footer */
  .footer_main {
    color:$text-color;
    padding: 5.6rem 0;
    font-size: 1.6rem;
    a {
      color:$text-color;
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
    @media (max-width: 575px) {
    }
    .container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        gap: 4rem 0;
      }
      @media (max-width: 768px) {
        justify-content: center;
      }
      .footer-col {
        width: 25rem;
        @media (max-width: 1200px) {
          display: flex;
          justify-content: center;
          width: 50%;
          align-items: center;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        .mod_customnav {
          @media (max-width: 1200px) {
            width: 60%;
          }
          @media (max-width: 992px) {
            width: 80%;
          }
          @media (max-width: 768px) {
            width: 50%;
          }
          @media (max-width: 500px) {
            width: 70%;
          }
          ul {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              margin-bottom: .8rem;
                &.facebook a {
                  font-size: 0;
                  &:before {
                    content: "\EA52";
                    font-family: kfont;
                    font-size: 2rem;
                  }
                  &:after {
                    display: none;
                  }
                }
              }
              &:first-child {
                font-family: "Satoshi Bold";
              }
            }
          }
        }
        a {
          position: relative;
          color: $text-color;
          text-decoration: none;
          transition: color .3s ease;

          &:hover {
            color: $text-lighter;
        }
        .menu-footer-1, .menu-footer-2 {
          li:first-child {
            pointer-events: none;
          }
        }
        .menu-footer-3 {
          li:nth-of-type(-n+4) {
            font-family: "Satoshi Bold";
          }
        }
        p{
          margin:0;
          font-family: "Satoshi Regular";
        }

      }
      .first-col {
        .mod_customnav {
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          @media (max-width: 768px) {
            flex-wrap: wrap;
          }
          .logo-footer {
            @media (max-width: 768px) {
              display: flex;
              justify-content: center;
            }
            img{
              width: 12.7rem;
              @media (max-width: 768px) {
                width: 10rem;
                margin-bottom: 2rem;
              }
            }
          }
          .ce_text {
            display: flex;
            gap: 1rem;
          }
          .adresse {
            &:before {
              content: "\EA5B";
              font-size: 2rem;
              font-family: kfont;
            }
          }
          .numero {
            &:before {
              content: "\EA5E";
              font-size: 2rem;
              font-family: kfont;
            }
          }
          .fax {
            &:before {
              content: "\EA53";
              font-size: 2rem;
              font-family: kfont;
            }
          }
          .mail {
            &:before {
              content: "\EA59";
              font-size: 2rem;
              font-family: kfont;
            }
          }
        }
      }
    }
  }
}