/* Main menu */
.main_menu {
  @media (max-width: 991px) {
    text-align: center;
  }
  ul {
    position:relative;
    padding:0;
    margin:0;
    white-space:nowrap;
    display: flex;
    justify-content: flex-start;
    li {
      position: relative;
      list-style-type:none;
      a {
        font-family: 'Satoshi Medium', sans-serif;
        font-size: 1.8rem;
        @media (max-width: 1105px) {
          font-size: 1.6rem;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.level_2 {
      background-color: transparent;
      position:absolute;
      padding-top: .4rem;
      top:-200rem;
      opacity:0;
      z-index: 2;
      transition:top 0.03s ease, opacity 0.6s ease;
      left:0;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      >li {
        background-color: $text-lighter;
        padding: 0 2.4rem;
        &.first {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding-top: 1.6rem;
        }
        &.last {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding-bottom: 1.6rem;
        }
        >a {
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          color: $text-color !important;
          padding: .4rem 0 !important;
          transition:all 0.6s ease !important;
        }
        &:hover {
          .level_3 {
            transition:top 0.03s ease, opacity 0.6s ease;
            opacity:1;
            pointer-events: all;
          }
        }
        >.level_3 {
          position: absolute;
          top: -.4rem;
          left: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          opacity:0;
          pointer-events: none;
          padding-top: .4rem;
          background-color: transparent;
          padding-left: .4rem;
          li {
            background-color: $text-lighter;
            padding: 0 2.4rem;
            &.first {
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              padding-top: 1.6rem;
            }
            &.last {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              padding-bottom: 1.6rem;
            }
            a{
              color: $text-color !important;
              padding: .4rem 0 !important;
              transition:all 0.6s ease !important;
            }
          }
        }
      }
      a.submenu{
        &:after {
          content:"\EA4D";
          font-family: kfont;
          font-size: 2rem;
          display: inline-flex;
          transform: rotate(-90deg);
        }
      }
    }
    &.level_1 {
      gap: 6.4rem;
      @media (max-width: 1105px) {
        gap: 2rem;
      }
      >li {
        display: flex;
        align-items: center;
        cursor: pointer;
        &::after {
          content:"\EA4D";
          font-family: kfont;
          font-size: 2rem;
          display: inline-flex;
          margin-left: -1rem;
          transform: rotate(-0.25turn);
          transition: transform .3s;
        }
        &:hover {
          &::after {
            transform: rotate(0turn);
            content:"\EA4D";
            font-family: kfont;
            font-size: 2rem;
            display: inline-flex;
            margin-left: -1rem;
          }
          >.level_2 {
            transition:top 0.03s ease, opacity 0.6s ease;
            top:100%;
            opacity:1;
          }
        }
        &.nav-vert {
          a:hover {
            color: $brand-primary;
          }
          .level_2 {
            a:hover {
              color: $brand-primary !important;
            }
            .level_3 {
              a:hover {
                color: $brand-primary !important;
              }
            }
          }
        }
        &.nav-bleu {
          a:hover {
            color: $brand-secondary;
          }
          .level_2 {
            left: -10rem;
            a:hover {
              color: $brand-secondary !important;
            }
            .level_3 {
              a:hover {
                color: $brand-secondary !important;
              }
            }
          }
        }

        a {
          display:block;
          padding: 3rem 2rem;
          color: $text-color;
          transition: all .3s;


          &.nav-vert {
            text-decoration: underline $brand-primary;
            text-decoration-thickness: 0.2rem;
          }
          &.nav-bleu {
            text-decoration: underline $brand-secondary;
            text-decoration-thickness: 0.2rem;
          }
        }
        > a {
          width: 100%;
        }
      }
    }
  }
}

/* Sticky */
.header {
  background-color: #FFFFFF;
  position: relative;
  &.isStuck {
    .main_menu {
      ul {
        &.level_1 {
          >li {
            a {
              padding-top: 1.8rem;
              padding-bottom: 1.8rem;
            }
          }
        }
      }
    }
  }
}